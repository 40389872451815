import { useState, useEffect } from "react";
import "./drop-down-styles.css";
import axios from "axios";

// const url_local = 'http://localhost:5000'
const url_local = "https://nba-xword-server-9b08b9cf70c6.herokuapp.com";
const url_main_shortened = "http://api.shor.tz";
const url_suffix = "/flask/names";

/**
 *
 * Helper function for interpreting the date time name string of the puzzle database entires
 *
 * @param {string} dateStr - of the form "MINI-{YYYY}_{MM}_{DD}-{HH}_{mm}_{SS}", which is represented in 24 hour time
 * @returns {string} Formatted date time, of the form "{Month} {Day}{DaySuffix}, {YYYY} @ {hh}:{mm} {AM/PM}"
 */
function formatDateString(dateStr) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const suffixes = ["th", "st", "nd", "rd"];

  // Remove the "MINI-" prefix
  const cleanedDateStr = dateStr.replace("MINI-", "");

  const [datePart, timePart] = cleanedDateStr.split("-");
  const [year, month, day] = datePart.split("_").map(Number);
  const [hour, minute] = timePart.split("_").map(Number);

  // Formatting day with suffix
  const daySuffix =
    day % 10 < 4 && Math.floor(day / 10) !== 1
      ? suffixes[day % 10]
      : suffixes[0];

  // Converting 24-hour format to 12-hour format
  const hour12 = hour % 12 || 12;
  const amPm = hour < 12 ? "AM" : "PM";

  return `${months[month - 1]} ${day}${daySuffix}, ${year} @ ${hour12}:${String(
    minute
  ).padStart(2, "0")} ${amPm}`;
}

const DynamicDropdown = ({ onSelectionChange, onDataLoaded, itemCount }) => {
  const [items, setItems] = useState([]); // State for storing dropdown items
  const [selectedItem, setSelectedItem] = useState(""); // State for the currently selected item

  // useEffect hook to fetch data when the component mounts
  useEffect(() => {
    axios.get(url_local + url_suffix).then((response) => {
      const sortedItems = response.data.puzzleNames.sort().reverse();
      setItems(sortedItems); // Update the items state with fetched data
      if (onDataLoaded) onDataLoaded(sortedItems); // Notify parent component about data load
      if (itemCount) itemCount(sortedItems.length);
    });
  }, []);

  // Handler for when an item is selected from the dropdown
  const handleChange = (event) => {
    setSelectedItem(event.target.value); // Update the selectedItem state
    onSelectionChange(event.target.value); // Notify parent component about the selection
  };

  // Render the dropdown component
  return (
    <div className="label-container" >
      <label htmlFor="puzzleSelect" className="selectLabel">
        Choose from {items.length} previously generated puzzles:
      </label>
      <div className="dropdown-container">
        {" "}
        {/* Container with styling */}
        <select value={selectedItem} onChange={handleChange} id="puzzleSelect">
          <option value="" disabled>
            Select a puzzle...
          </option>{" "}
          {/* Default option */}
          {items.map(
            (
              item // Map each item to an option element
            ) => (
              <option key={item} value={item}>
                {formatDateString(item)}
              </option>
            )
          )}
        </select>
      </div>
    </div>
  );
};

export default DynamicDropdown;
