import React from 'react';
import "./sidebar-styles.css"

const Sidebar = ({ position }) => {
    return (
        <aside className={`sd ${position}`}>
            {/* Placeholder content */}
            {/* <p>{`Sidebar ${position}`}</p> */}
        </aside>
    );
};

export default Sidebar;