import React, { useState, useEffect } from "react";
import "./waiting-modal-styles.css";
// import { CircularProgress } from '@mui/material';

const api_url = "localhost:5000/flask/puzz";

const WaitingModal = ({ isOpen, onClose, onCompleted }) => {
  if (!isOpen) return null;

  return (
    <div className="waiting-modal-backdrop">
      <div className="waiting-modal-content">
        <img src="/spinner.png" className="spinner" alt="spinner" />

        <h2>Generating Puzzle...</h2>

        <div className="waiting-modal-note">
          Note: Puzzle Generator is still a WIP.
          <p>  Known issues: 
          <br /> - Outdated/inaccurate/hallucinatory clue information (Model last trained in late 2022)
          <br /> - Repeated words in grid (Can result in a completely mirrored grid)
          <br /> - Commonly repeated words in each puzzle (i.e. 'NYK', 'LBJ')
          </p>
        If the puzzle is not generated within 20 seconds, please refresh the page and try again.  
        </div>
      </div>
    </div>
  );
};

export default WaitingModal;
