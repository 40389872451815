import React, { useState, useEffect } from "react";
import "./home-styles.css";
import ShareButtons from "../../components/share-buttons";
import DynamicDropdown from "../../components/drop-down";
import Layout from "../../components/layout";
import LandingModal from "../../components/landing-modal";
import WaitingModal from "../../components/waiting-modal";
import DropdownList from "react-widgets/DropdownList";
import Button from "@mui/material/Button";
import "react-widgets/styles.css";

import { useNavigate } from "react-router-dom";

import xword_dict from "../../puzzles";

// let local_url = "http://127.0.0.1:5000/flask"
let gen_suffix = "/generate";
let load_suffix = "/puzzle?name=";
let local_url = "https://nba-xword-server-9b08b9cf70c6.herokuapp.com/flask";

let num_puzzles = 3;

let puzz_names = Object.keys(xword_dict);
const Form = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/", { fromHome: true });
  }, []);

  const [isMouseOverTopHalf, setIsMouseOverTopHalf] = useState(false);
  const [isMouseOverBottomHalf, setIsMouseOverBottomHalf] = useState(false);

  const [themeWord, setThemeWord] = useState("");
  const [gridSize, setGridSize] = useState("");
  const [isCurrentlyGenerating, setIsCurrentlyGenerating] = useState(false);

  const handleDataLoaded = (loadedData) => {
    // Data loaded logic
  };

  const handleModalClose = () => {
    setIsCurrentlyGenerating(false);
  };

  // useEffect(() => {

  // }, [isCurrentlyGenerating]);

  const updateThemeSize = (word) => {
    setThemeWord(word);

    if (word.length > gridSize) {
      setGridSize(word.length);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!themeWord) {
      return;

      // } else if (!(themeWord.length < gridSize)){

      //     return;
    } else {
      // navigate(`/crossword?theme=${themeWord}&size=${gridSize}`);
      try {
        // Replace with your actual API call
        const response = await fetch(local_url + load_suffix + themeWord);
        const data = await response.json();
        const puzz_data = data.data.puzzle;
        // console.log(data)

        console.log(puzz_data);
        // Navigate to the new page with response data
        navigate("/crossword", { state: { puzz_data } });
        // navigate('/crossword')
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle any errors here
      } finally {
        // setIsCurrentlyGenerating(false);
      }
    }
  };

  const handleGenerate = async (event) => {
    event.preventDefault();
    setIsCurrentlyGenerating(true);
    // TODO: COMMENT THIS CODE BEFORE TESTING SPINNER
    try {
      const response = await fetch(local_url + gen_suffix);
      const data = await response.json();
      const puzz_data = data.data.puzzle;
      console.log(puzz_data);

      // Navigate to the new page with response data
      navigate("/crossword", { state: { puzz_data } });
      // navigate('/crossword')
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle any errors here
    } finally {
      // setIsCurrentlyGenerating(false);
    }
  };

  const handleDoneGenerating = () => {
    setIsCurrentlyGenerating(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        className="bottom-half"
        onMouseEnter={() => setIsMouseOverBottomHalf(true)}
        onMouseLeave={() => setIsMouseOverBottomHalf(false)}
      >
        <div className="label-and-button-container">
          <div className="label-container">
            <label className="select-label" htmlFor="generateButton">
              Generate a brand new mini puzzle:
            </label>
          </div>

          <div className="button-container">
            <Button
              className="big-button"
              onClick={handleGenerate}
              id="generateButton"
              variant={isMouseOverBottomHalf ? "contained" : "outlined"}
            >
              Generate!
            </Button>
          </div>
        </div>
      </div>
      <div className="divider-container">
        <hr className="divider" />
        <div className="or">Or:</div>
        <hr className="divider" />
      </div>
      <div
        className="top-half"
        onMouseEnter={() => setIsMouseOverTopHalf(true)}
        onMouseLeave={() => setIsMouseOverTopHalf(false)}
      >
        <div className="label-and-button-container">
          {/* <div className="label-container"> */}
          <DynamicDropdown
            onSelectionChange={(value) => setThemeWord(value)}
            onDataLoaded={handleDataLoaded}
          />
          {/* </div> */}
          <div className="button-container">
            <Button
              className="big-button"
              onClick={handleSubmit}
              variant={isMouseOverTopHalf ? "contained" : "outlined"}
            >
              Go!
            </Button>
          </div>
        </div>
      </div>

      {isCurrentlyGenerating && (
        <WaitingModal
          isOpen={isCurrentlyGenerating}
          onClose={handleModalClose}
          onCompleted={handleDoneGenerating}
        />
      )}
    </form>
  );
};

const Home = () => {
  return <Layout Content={Form} />;
};

export default Home;
