import { useState, useEffect } from "react";
import "./timer-styles.css";

// react component for a count up timer for the
const CountUpTimer = ({ isActive, onStop, timerRef}) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let interval = null;

    if (isActive) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    } else {
      clearInterval(interval);
      if (onStop) onStop(formatTimeFull(seconds)); // Pass formatted time to callback
    }
    return () => clearInterval(interval);
  }, [isActive, onStop, seconds]);

  // function to format the time in HH:MM:SS
  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    let timeString = "";
    if (minutes < 10 && minutes > 0) {
      timeString += `${minutes}:`;
    } else if (minutes === 0) {
      timeString += `0:`;
    } else {
      timeString += `${minutes.toString()}:`;
    }
    timeString += seconds.toString().padStart(2, "0");

    return timeString;
  };

  const timeOut = (totalSeconds) => {
    if (totalSeconds <= 5999) {
      return formatTime(totalSeconds);
    } else {
      if (totalSeconds > 2) {
        if (totalSeconds % 2 == 0) {
          return "   ";
        }
        return "UH:OH";
      }
    }
  };

  /**
   * Format time that is used when the timer stops on puzzle completion.
   * @param {*} totalSeconds 
   * @returns 
   */
  const formatTimeFull = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Building time string conditionally
    let timeString = "";
    if (hours > 0) {
      timeString += `${hours.toString().padStart(2, "0")}:`;
    }
    if (minutes > 0 || hours > 0) {
      timeString += `${minutes.toString().padStart(2, "0")}`;
    } 
    if (minutes <= 0 ){
        timeString += "0"
    }

    timeString += ":";
    timeString += seconds.toString().padStart(2, "0");

    return timeString;
  };

  return (
    <div className="timer-container">
      <div className="timer timer-background">88:88</div>
      <div className="timer timer-display">{timeOut(seconds)}</div>
    </div>
  );
};

export default CountUpTimer;
