import React from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import { Redirect } from 'react-router-dom';

import Crossword from "@jaredreisinger/react-crossword";

import {
  CrosswordGrid,
  ThemeProvider,
  CrosswordProvider,
  DirectionClues,
} from "@jaredreisinger/react-crossword/";

import "./xword-styles.css";

import Layout from "../../components/layout";
import CountUpTimer from "../../components/timer";
import WinnerWinner from "../../components/winner-winner";
import CurrentClue from "../../components/current-clue";
import { useNavigate, useLocation } from "react-router-dom";

import Button from "@mui/material/Button";

const CLEAR_DEFAULT = "Clear Grid";
const REVEAL_DEFAULT = "Reveal Grid!";

const CLEAR_CONFIRM = "Clear";
const REVEAL_CONFIRM = "Don't Clear";

const REVEAL_NEXT = "Next Puzzle!";

/**
 * Xwordlet component represents a crossword puzzle page.
 * It handles the logic and rendering of the crossword puzzle,
 * including the timer, clues, grid, reveal button, and clear button.
 */
const Xwordlet = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state?.puzz_data;

 
  // console.log("mama mia?");
  // console.log(data);

  // logic and states for the count up timer component
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [finalTime, setFinalTime] = useState(""); // State to store the final timer value
  const [showWinnerWinner, setShowWinnerWinner] = useState(false);
  const [crosswordCorrect, setCrosswordCorrect] = useState(false);

  const winStreak = 3;

  const handleTimerStop = (timeElapsed) => {
    setIsTimerActive(false); // Stop the timer
    setFinalTime(timeElapsed); // Store the final time
  };

  const puzzleRef = useRef(null);
  const timerRef = useRef(null)

  const isClearing = useRef(false);

  const [currentClueNum, setCurrentClueNum] = useState("");
  const [currentClueDir, setCurrentClueDir] = useState("");
  const [currentClueText, setCurrentClueText] = useState("");

  const [clearButtonText, setClearButtonText] = useState(CLEAR_DEFAULT);
  const [revealButtonText, setRevealButtonText] = useState(REVEAL_DEFAULT);

  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);

 

  useEffect(() => {
    // scroll to hide the header
    if (window.innerWidth <= 800) {
      window.scrollTo(0, 100); // adjust the number to your header's height
    }

    // setPuzzData(xword_dict[params.get('theme')])
    // puzzleRef.current.focus();
  }, []);

  // const [puzzData, setPuzzData] = useState(xword_dict[params.get('theme')]);
  const [puzzData, setPuzzData] = useState(data);

  const handleFillGrid = () => {
    if (puzzleRef.current) puzzleRef.current.fillAllAnswers();
  };

  const navigateHome = () => {
    navigate(`/`);
  };

  const revealButtonAction = useRef(handleFillGrid);

  // const clearButtonAction = clearGrid;

  const handleDontClear = () => {
    setRevealButtonText(REVEAL_DEFAULT);
    setClearButtonText(CLEAR_DEFAULT);

    revealButtonAction.current = handleFillGrid;
    setIsClearButtonPressed(false); // Reset clear button state
  };

  const [isClearButtonPressed, setIsClearButtonPressed] = useState(false);

  const onClearClick = useCallback(() => {
    if (isClearButtonPressed) {
      puzzleRef.current.reset();
      let wasCrosswordCorrect = crosswordCorrect;
      crosswordCorrect && setCrosswordCorrect(false);
      setIsClearButtonPressed(false); // Reset clear button state
      setIsTimerActive(true); // Reset the timer
      revealButtonAction.current = handleFillGrid; // Change reveal button action to reveal the grid

      if (!wasCrosswordCorrect){
        setRevealButtonText(REVEAL_DEFAULT); // Change reveal button text back to default
      } else {
        setRevealButtonText(REVEAL_NEXT); // Change reveal button text back to 
        setCrosswordCorrect(true);
      }
      isClearing.current = false;
      
      setClearButtonText(CLEAR_DEFAULT);

    } else {
      isClearing.current = true;
      setIsClearButtonPressed(true); // Set clear button state
      setClearButtonText(CLEAR_CONFIRM); // Change clear button text

      setRevealButtonText(REVEAL_CONFIRM); // Change reveal button text
      revealButtonAction.current = handleDontClear;
    }
  }, [
    isClearButtonPressed,
    setIsClearButtonPressed,
    setIsTimerActive,
    handleFillGrid,
  ]);

  const onRevealClick = useCallback(() => {
    if (revealButtonAction.current) revealButtonAction.current();

    // puzzleRef.current.fillAllAnswers();
  }, [puzzleRef]);

  const onCurrentClueChange = useCallback((direction, number) => {
    setCurrentClueDir(direction);
    setCurrentClueNum(number);
    // setCurrentClueText(puzzData[direction][number]["clue"]);
  });

  const onCellChange = useCallback(
    (row, column, char) => {
      if (puzzleRef.current) {
        const cells = puzzleRef.current.cells; // All cells in the crossword grid
        // const clues = puzzleRef.current.props.data; // All clues in the crossword

        // // Iterate through each direction (across, down)
        // for (let direction in clues) {
        //   // Iterate through each clue in current direction
        //   for (let clueNum in clues[direction]) {
        //     // All cells for current clue
        //     let clueCells = clues[direction][clueNum].cells;

        //     // Iterate within current clue cells
        //     for (let clueCell of clueCells) {
        //       // Iterate through each cell of crossword
        //       for (let cellId in cells) {
        //         // When currently active cell of crossword matches any cell of current clue
        //         if (
        //           cellId === clueCell.toString() &&
        //           cells[cellId].input === document.activeElement
        //         ) {
        // Update the current clue
        onCurrentClueChange("down", Number(row));
      }
      //         }
      //       }
      //     }
      //   }
      // }
    },
    [onCurrentClueChange, puzzleRef]
  );

  const onCrosswordCorrect = useCallback((correct) => {
    if (correct && !isClearing.current) {
      setCrosswordCorrect(true);
      setRevealButtonText(REVEAL_NEXT);
      revealButtonAction.current = navigateHome;
      if (isTimerActive) setShowWinnerWinner(true);
      setIsTimerActive(false);
      // Show the winnerwinner modal when the crosssword is complete and correct
    }
  });

  const onLoadedCorrect = useCallback(() => {});
  const onAnswerComplete = useCallback(() => {});
  const onCorrect = useCallback(() => {});
  const onAnswerCorrect = useCallback(() => {});
  const onAnswerIncorrect = useCallback(() => {});
  const onCrosswordComplete = useCallback(() => {});

  const handleCloseWinnerWinner = () => {
    setShowWinnerWinner(false);
  };

  if (data === undefined || data === null) {
    navigate('/');
    return null;
    
  }

  // if (!location.state || !location.state.fromHome) {
  //   navigate('/');
  // }


  return (
    <div className={`xword-holder ${crosswordCorrect ? 'crossword-disabled' :''}`}>
      <ThemeProvider
        theme={{
          allowNonSquare: false,
          columnBreakpoint: "2000px",
          gridBackground: "#111",
          cellBackground: "#696969",
          cellBorder: "#111",
          textColor: "#fff",
          numberColor: "#B3B3B3",
          focusBackground: "#E03A3E",
          highlightBackground: "#00337A",
        }}
      >
        <CrosswordProvider
          data={puzzData}
          useStorage={false}
          ref={puzzleRef}
          onCorrect={onCorrect}
          onClueSelected={onCurrentClueChange}
          onLoadedCorrect={onLoadedCorrect}
          onCellChange={onCellChange}
          onAnswerCorrect={onAnswerCorrect}
          onAnswerIncorrect={onAnswerIncorrect}
          onAnswerComplete={onAnswerComplete}
          onCrosswordComplete={(event) => onCrosswordComplete(event)}
          onCrosswordCorrect={onCrosswordCorrect}
        >

            <div   className={`crossword-layout `}
            >
              <div className={`dadiv`}>
              {/* <div className={`dadiv`}> */}
            
                  <CountUpTimer isActive={isTimerActive} onStop={handleTimerStop} timerRef={timerRef}/>
                {window.innerWidth <= 800 && (
                  <div className="highlighted-clue">
                    <CurrentClue
                      dir={currentClueDir}
                      num={currentClueNum}
                      text={currentClueText}
                      switchDirection={() => {}}
                    />
                    {/* <Clue number={currentClueNum} direction={currentClueDir}>
                      {currentClueDir}, {currentClueText}
                    </Clue> */}
                  </div>
                )}
                <CrosswordGrid />
              </div>
              <div className={`clue-div ${crosswordCorrect ? 'crossword-disabled' : ''}`}>
                <DirectionClues direction="across" />
                <DirectionClues direction="down" />
              </div>
            </div>
        </CrosswordProvider>
      </ThemeProvider>

      <div className="button-div">
        <Button
          className={"big-button crossword"}
          variant={isClearButtonPressed ? "outlined" : "contained"}
          color={isClearButtonPressed ? "error" : "primary"}
          disableElevation={isClearButtonPressed}
          onClick={onRevealClick}
          sx={{
            outlineStyle: "solid",
            textShadow: "2px 2px rgba(0, 0, 0, 0.5)",
          }}
        >
          {revealButtonText}
        </Button>

        <Button
          className={"big-button crossword"}
          variant="contained"
          color={isClearButtonPressed ? "success" : "secondary"}
          onClick={onClearClick}
          sx={{
            textShadow: "2px 2px rgba(0, 0, 0, 0.5)",
            

          }}
        >
          {clearButtonText}
        </Button>
      </div>
      {showWinnerWinner && (
        <WinnerWinner
          timerValue={finalTime}
          currentStreak={winStreak}
          isOpen={showWinnerWinner}
          onClose={handleCloseWinnerWinner}
          navigator={navigate}
          onNextPuzzle={navigateHome}
        />
      )}
    </div>
  );
};


const Xword = () => {
  return <Layout Content={Xwordlet} />;
};

export default Xword;
