import React from 'react';
import './header-styles.css'; // Assuming you have a separate CSS file for the Header

const Header = () => {
  return (
    <header className="page-header">
      <a href="/" className='header-link'>
      <h1 className="header__title">NBA Crossword Generator</h1>
      <p className="header__subtitle">powered by AI</p>
      </a>
    </header>
  );
};

export default Header;
