let xword_dict = {
  "ANTETOKOUNMPO": {
    "across": {
      "1": {
        "clue": "Last name of the prominent Spurs power forward",
        "answer": "DUNCAN",
        "row": 0,
        "col": 2
      },

      "2": {
        "clue": "'I'm ___!', or '___othy'",
        "answer": "HIM",
        "row": 1,
        "col": 0
      },

      "3": {
        "clue": "Walt '_____' Frazier",
        "answer": "CLYDE",
        "row": 2,
        "col": 2
      },
      "4": {
        "clue": "Initials of a longtime Spurs and Blazers PF",
        "answer": "LMA",
        "row": 2,
        "col": 8
      },
      "5": {
        "clue": "'The Greek Freak'",
        "answer": "ANTETOKOUNMPO",
        "row": 4,
        "col": 0
      },
      "6": {
        "clue": "Initials for a high-flying NBA legend",
        "answer": "DRJ",
        "row": 6,
        "col": 8
      },
      "7": {
        "clue": "First name of the Australian NBA player Simmons",
        "answer": "BEN",
        "row": 8,
        "col": 8
      },
      "8": {
        "clue": "Alma mater for Zion, RJ, and Cam",
        "answer": "DUKE",
        "row": 9,
        "col": 3
      },
      "9": {
        "clue": "Last name of Otis, or a winged animal's tune",
        "answer": "BIRDSONG",
        "row": 11,
        "col": 1
      },
      "10": {
        "clue": "First name of Claxton",
        "answer": "NIC",
        "row": 7,
        "col": 0
      },
    },
    "down": {
      "1": {
        "clue": "Nickname of an NBA player known for his toughness and tenacity",
        "answer": "DMC",
        "row": 0,
        "col": 2
      },
      "2": {
        "clue": "Michael's royal nickname",
        "answer": "HISAIRNESS",
        "row": 1,
        "col": 0
      },
      "3": {
        "clue": "Last name of David or Courtney",
        "answer": "LEE",
        "row": 2,
        "col": 3
      },
      "4": {
        "clue": "Last name of a former Bucks guard, Sidney",
        "answer": "MONCRIEF",
        "row": 2,
        "col": 9
      },
      "5": {
        "clue": "Nickname of a legendary NBA coach",
        "answer": "DOC",
        "row": 3,
        "col": 7
      },
      "6": {
        "clue": "Last name of an NBA player who was part of the Lakers' Showtime era",
        "answer": "JONES",
        "row": 3,
        "col": 12
      },
      "7": {
        "clue": "Nickname of a former NBA player with a strong physique",
        "answer": "TRUCK",
        "row": 4,
        "col": 2
      },
      "8": {
        "clue": "Elusive guard Syd's nickname, not quite the Kraken",
        "answer": "THESQUID",
        "row": 4,
        "col": 4
      },
      "9": {
        "clue": "First name of a crafty PG in Miami",
        "answer": "KYLE",
        "row": 6,
        "col": 6
      }
    }
  },
  "IN_SEASON_TOURNAMENT": {
    "across": {
      "0": {
        "clue": "Initials for LeBron James",
        "answer": "LBJ",
        "row": 0,
        "col": 2
      },
      "1": {
        "clue": "Allen who hit the championship-winning 3-pointer for the Heat",
        "answer": "RAY",
        "row": 1,
        "col": 6
      },
      "2": { "clue": "With 3 and 5 across, name of the NBA's new event won by the Lakers", "answer": "IN", "row": 3, "col": 0 },
      "3": { "clue": "See 2 across", "answer": "SEASON", "row": 3, "col": 3 },
      "4": {
        "clue": "Johnson, known as 'Iso ___ '",
        "answer": "JOE",
        "row": 5,
        "col": 5
      },
      "5": {
        "clue": "See 2 across",
        "answer": "TOURNAMENT",
        "row": 7,
        "col": 0
      }
    },
    "down": {
      "0": {
        "clue": "Garnett or Love",
        "answer": "KEVIN",
        "row": 0,
        "col": 0
      },
      "1": { "clue": "Aldridge's nickname", "answer": "LMA", "row": 0, "col": 2 },
      "2": { "clue": "Harden's first name", "answer": "JAMES", "row": 0, "col": 4 },
      "3": {
        "clue": "Last name for Karl or Moses",
        "answer": "MALONE",
        "row": 0,
        "col": 7
      },
      "4": {
        "clue": "Opposite of clean, as in a foul",
        "answer": "DIRTY",
        "row": 4,
        "col": 9
      },
      "5": { "clue": "Chris of the Clippers", "answer": "PAUL", "row": 5, "col": 2 }
    }
  }, "LOSANGELESLAKERS": {
    "across": {
      "1": {
        "clue": "Initials of both a King and a President",
        "answer": "LBJ",
        "row": 1,
        "col": 5
      },
      "2": {
        "clue": "Grant or George",
        "answer": "HILL",
        "row": 2,
        "col": 2
      },
      "3": {
        "clue": "First name of NBA player Ingram",
        "answer": "BRANDON",
        "row": 2,
        "col": 8
      },
      "4": {
        "clue": "Magic or Tyler",
        "answer": "JOHNSON",
        "row": 4,
        "col": 2
      },
      "5": {
        "clue": "Nickname for Anthony?",
        "answer": "MELO",
        "row": 6,
        "col": 2
      },
      "6": {
        "clue": "NBA team with 17 championships",
        "answer": "LOSANGELESLAKERS",
        "row": 8,
        "col": 0
      },
      "7": {
        "clue": "LeBron's nickname",
        "answer": "BRONBRON",
        "row": 10,
        "col": 0
      },
      "8": {
        "clue": "Duncan or Hardaway",
        "answer": "TIM",
        "row": 10,
        "col": 12
      },
      "9": {
        "clue": "From 1988-1998, a tree that grew in the Garden",
        "answer": "OAK",
        "row": 11,
        "col": 9
      },
      "10": {
        "clue": "Nickname for LeBron James",
        "answer": "KINGJAMES",
        "row": 13,
        "col": 2
      }
    },
    "down": {
      "1": {
        "clue": "Three-pointer master Ray",
        "answer": "ALLEN",
        "row": 0,
        "col": 5
      },
      "2": {
        "clue": "Nickname for Kobe Bryant's wine label",
        "answer": "VINO",
        "row": 1,
        "col": 3
      },
      "3": {
        "clue": "Actor Nicholson",
        "answer": "JACK",
        "row": 1,
        "col": 10
      },
      "4": {
        "clue": "Nuggets big man Nikola",
        "answer": "JOKIC",
        "row": 1,
        "col": 13
      },
      "5": {
        "clue": "Simmons or Wallace",
        "answer": "BEN",
        "row": 2,
        "col": 8
      },
      "6": {
        "clue": "LeBron or Harden",
        "answer": "JAMES",
        "row": 4,
        "col": 2
      },
      "7": {
        "clue": "Brother of Cliff",
        "answer": "CHRIS",
        "row": 4,
        "col": 15
      },
      "8": {
        "clue": "First name of George, last name of Chris",
        "answer": "PAUL",
        "row": 5,
        "col": 0
      },
      "9": {
        "clue": "LeBron's self-proclaimed title",
        "answer": "CHOSENONE",
        "row": 5,
        "col": 9
      },
      "10": {
        "clue": "Shaquille or Jermaine",
        "answer": "ONEAL",
        "row": 5,
        "col": 11
      },
      "11": {
        "clue": "Mailman Karl",
        "answer": "MALONE",
        "row": 6,
        "col": 7
      },
      "12": {
        "clue": "Durant or Garnett",
        "answer": "KEVIN",
        "row": 7,
        "col": 13
      },
      "13": {
        "clue": "Payton or Payton",
        "answer": "GARY",
        "row": 8,
        "col": 5
      },
      "14": {
        "clue": "Larry the legend",
        "answer": "BIRD",
        "row": 10,
        "col": 0
      },
      "15": {
        "clue": "Scottie or Gladys",
        "answer": "PIP",
        "row": 12,
        "col": 3
      }
    }
  }, "NBAONXMASDAY": {
    "across": {
      "1": {
        "clue": "Hawks guard Jeff",
        "answer": "TEAGUE",
        "row": 0,
        "col": 0
      },
      "2": {
        "clue": "Moniker for Tracy McGrady",
        "answer": "TMAC",
        "row": 0,
        "col": 7
      },
      "3": {
        "clue": "Former Jazz center Mehmet",
        "answer": "OKUR",
        "row": 2,
        "col": 0
      },
      "4": {
        "clue": "nickname for for Bucks twin Big",
        "answer": "BROPEZ",
        "row": 2,
        "col": 5
      },
      "5": {
        "clue": "The most wonderful time of the year?",
        "answer": "NBAONXMASDAY",
        "row": 4,
        "col": 0
      },
      "6": {
        "clue": "Knicks guard Courtney",
        "answer": "LEE",
        "row": 7,
        "col": 0
      },
      "7": {
        "clue": "Initials of Aldridge",
        "answer": "LMA",
        "row": 7,
        "col": 8
      },
      "8": {
        "clue": "Nash or Kerr, for instance",
        "answer": "STEVE",
        "row": 8,
        "col": 3
      },
      "9": {
        "clue": "Wallace of the Pistons",
        "answer": "BEN",
        "row": 9,
        "col": 8
      },
      "10": {
        "clue": "SuperSonics great Shawn",
        "answer": "KEMP",
        "row": 10,
        "col": 1
      }
    },
    "down": {
      "1": {
        "clue": "Suns Eric, or NASCAR Driver Jeff",
        "answer": "GORDON",
        "row": 0,
        "col": 3
      },
      "2": {
        "clue": "Isaiah or Isiah",
        "answer": "THOMAS",
        "row": 0,
        "col": 7
      },
      "3": {
        "clue": "Former Knick and Shocker, but not a Butcher or Candlestick Maker",
        "answer": "BAKER",
        "row": 4,
        "col": 1
      },
      "4": {
        "clue": "Grizzlies forward Tillman",
        "answer": "XAVIER",
        "row": 4,
        "col": 5
      },
      "5": {
        "clue": "First name for Jamison",
        "answer": "ANTAWN",
        "row": 4,
        "col": 10
      },
      "6": {
        "clue": "First name for Cassel or Hauser",
        "answer": "SAM",
        "row": 8,
        "col": 3
      }
    }
  }, "NBAPLAYOFFS": {
    "across": {
      "1": {
        "clue": "Thunder Dan of the Suns and Heat",
        "answer": "MAJERLE",
        "row": 0,
        "col": 0
      },
      "2": {
        "clue": "Cousins of the Sacramento Kings and Pelicans",
        "answer": "DEMARCUS",
        "row": 2,
        "col": 2
      },
      "3": {
        "clue": "Gasol of the Grizzlies and current Raptors assistant coach",
        "answer": "MARC",
        "row": 2,
        "col": 4
      },
      "4": {
        "clue": "The most exciting time of the NBA season",
        "answer": "NBAPLAYOFFS",
        "row": 4,
        "col": 0
      },
      "5": {
        "clue": "Former Pistons guard Hamilton",
        "answer": "RIP",
        "row": 6,
        "col": 6
      },
      "6": {
        "clue": "Paul, once of the Oklahoma City Thunder",
        "answer": "GEORGE",
        "row": 7,
        "col": 0
      },
      "7": {
        "clue": "Xavier of the Seattle SuperSonics and Phoenix Suns",
        "answer": "MCDANIEL",
        "row": 9,
        "col": 0
      }
    },
    "down": {
      "1": {
        "clue": "Point guard of the New York Knicks",
        "answer": "MASON",
        "row": 0,
        "col": 0
      },
      "2": {
        "clue": "Gasol of the Lakers and Bulls",
        "answer": "PAU",
        "row": 0,
        "col": 8
      },
      "3": {
        "clue": "Warriors forward known for his defensive prowess",
        "answer": "DRAYMOND",
        "row": 2,
        "col": 2
      },
      "4": {
        "clue": "Greek god who shares a name with a cleaning product",
        "answer": "AJAX",
        "row": 2,
        "col": 5
      },
      "5": {
        "clue": "Thomas traded from the Celtics to the Cavaliers",
        "answer": "ISAIAH",
        "row": 3,
        "col": 10
      },
      "6": {
        "clue": "NBA Hall of Famer Thorpe",
        "answer": "OTIS",
        "row": 4,
        "col": 7
      }
    }
  },
  "WEMBANYAMA": {
    "across": {
      "1": {
        "clue": "French-born basketball phenom Victor",
        "answer": "WEMBANYAMA",
        "row": 1,
        "col": 1

      },
      "2": {
        "clue": "Last name for Ben or Jonathon",
        "answer": "SIMMONS",
        "row": 3,
        "col": 0
      },
      "3": {
        "clue": "Professional women's basketball league",
        "answer": "WNBA",
        "row": 4,
        "col": 7
      },
      "4": {
        "clue": "Network for 'Inside the NBA'",
        "answer": "TNT",
        "row": 5,
        "col": 0
      },
      "5": {
        "clue": "NBA team based in Charlotte",
        "answer": "CHA",
        "row": 6,
        "col": 6
      },
      "6": {
        "clue": "First name for NBA All-Star Walker",
        "answer": "ANTOINE",
        "row": 7,
        "col": 0
      },
      "7": {
        "clue": "Cavaliers on TV, maybe?",
        "answer": "CLE",
        "row": 8,
        "col": 8
      },
      "8": {
        "clue": "State known for its Jazz?",
        "answer": "UTAH",
        "row": 9,
        "col": 5
      },
      "9": {
        "clue": "Where the free-throw and lane lines meet",
        "answer": "ELBOW",
        "row": 10,
        "col": 0
      }
    },
    "down": {
      "1": {
        "clue": "Turkish-born NBA center Mehmet",
        "answer": "MEHMET",
        "row": 0,
        "col": 2
      },
      "2": {
        "clue": "Another name for the lane, or an art medium ",
        "answer": "PAINT",
        "row": 0,
        "col": 5
      },
      "3": {
        "clue": "Mr. Kidd of the NBA",
        "answer": "JASON",
        "row": 0,
        "col": 8
      },
      "4": {
        "clue": "NBA All-Star Lillard",
        "answer": "DAMIAN",
        "row": 0,
        "col": 10
      },
      "5": {
        "clue": "NBA player Jeff or Marquis",
        "answer": "TEAGUE",
        "row": 5,
        "col": 0
      },
      "6": {
        "clue": "Nickname for The Boston Celtics",
        "answer": "BOS",
        "row": 6,
        "col": 3
      },
      "7": {
        "clue": "Informal name for the Boston Celtics",
        "answer": "CELTS",
        "row": 6,
        "col": 6
      },
      "8": {
        "clue": "NBA legend Mutombo's nickname",
        "answer": "DEKE",
        "row": 7,
        "col": 10
      },
      "9": {
        "clue": "City known for its NBA team, the Bulls",
        "answer": "CHI",
        "row": 8,
        "col": 8
      }
    }


  }, "RISING_STARS": {
    "across": {
      "1": {
        "clue": "With 1 down, young talent featured in an All-Star Weekend exhibition",
        "answer": "RISING",
        "row": 0,
        "col": 0
      },
      "2": {
        "clue": " Hall of Famer Ming",
        "answer": "YAO",
        "row": 1,
        "col": 6
      },
      "3": {
        "clue": "First name for GSW's Green, for short",
        "answer": "DRAY",
        "row": 2,
        "col": 0
      },
      "4": {
        "clue": "Hairstyle for Jarret Allen?",
        "answer": "FRO",
        "row": 3,
        "col": 6
      },
      "5": {
        "clue": "____ Van Gundy, brother of Jeff",
        "answer": "STAN",
        "row": 4,
        "col": 2
      },
      "6": {
        "clue": "'Make some ______!'",
        "answer": "NOISE",
        "row": 6,
        "col": 2
      },
      "7": {
        "clue": "Hardaway who played for the Orlando Magic",
        "answer": "PENNY",
        "row": 8,
        "col": 2
      },
      // "8": {
      //   "clue": "Mid-Level Exception, briefly ",
      //   "answer": "MLE",
      //   "row": 5,
      //   "col": 3
      // }

    },
    "down": {
      "1": {
        "clue": "JJ, a player-turned-podcaster",
        "answer": "REDDICK",
        "row": 0,
        "col": 0
      },
      "2": {
        "clue": "See 1 across",
        "answer": "STARS",
        "row": 0,
        "col": 2
      },
      "3": {
        "clue": "MJ starts a line of cuttoff denim?",
        "answer": "AIR",
        "row": 1,
        "col": 7
      },
      "4": {
        "clue": "New Orleans Pelicans, briefly",
        "answer": "NOP",
        "row": 6,
        "col": 2
      },
      "5": {
        "clue": "Out of this world nickname for Wembanyama, with 'The'",
        "answer": "ALIEN",
        "row": 4,
        "col": 4
      },
      "6": {
        "clue": "First name for Majerle or Issel",
        "answer": "DAN",
        "row": 2,
        "col": 5
      },
      // "7": {
      //   "clue": "'NBA ____, the leagues outreach initiative",
      //   "answer": "ESPN",
      //   "row": 5,
      //   "col": 5
      // },
      "8": {
        "clue": "MJ starts a line of cuttoff denim?",
        "answer": "OSCAR",
        "row": 3,
        "col": 8
      },
    }
  },

  "LEAGUEPASS": {
    "across": {
      "1": {
        "clue": "Shortened name for the former Knicks player Latrell",
        "answer": "SPREE",
        "row": 0,
        "col": 0
      },
      "2": {
        "clue": "Nickname for the Detroit NBA team",
        "answer": "DET",
        "row": 1,
        "col": 5
      },
      "3": {
        "clue": "Nickname for a Toronto player with a short wingspan, maybe?",
        "answer": "TREX",
        "row": 2,
        "col": 0
      },
      "4": {
        "clue": "Informal term for the Toronto NBA team",
        "answer": "RAPS",
        "row": 3,
        "col": 4
      },
      "5": {
        "clue": "NBA streaming service often used by fans",
        "answer": "LEAGUEPASS",
        "row": 5,
        "col": 0
      },

      "6": {
        "clue": "'_____ over Muscle', another word for effort",
        "answer": "HUSTLE",
        "row": 7,
        "col": 4
      },
      "7": {
        "clue": "'___ of Regulation', once 48 minutes have elapsed",
        "answer": "END",
        "row": 7,
        "col": 0
      },
      "8": {
        "clue": "Abbreviation for San Antonio's NBA team",
        "answer": "SAS",
        "row": 8,
        "col": 0
      },
      "9": {
        "clue": "Lakers 6MOY, Husband of Khloe, and URI HoFer ",
        "answer": "ODOM",
        "row": 4,
        "col": 4
      },
      "10": {
        "clue": "_____ Cunningham, Drafted first overall by DET in 2021",
        "answer": "CADE",
        "row": 9,
        "col": 5
      },
    },
    "down": {
      "1": {
        "clue": "Blazers, on a scoreboard",
        "answer": "POR",
        "row": 0,
        "col": 1
      },
      "2": {
        "clue": "Nickname for the retired Miami Heat player",
        "answer": "DWADE",
        "row": 1,
        "col": 5
      },
      "3": {
        "clue": "Informal moniker for Andrew Wiggins",
        "answer": "WIGGS",
        "row": 1,
        "col": 9
      },
      "4": {
        "clue": "Informal term for the Minnesota NBA team",
        "answer": "TWOLVES",
        "row": 2,
        "col": 0
      },
      "5": {
        "clue": "Last name of the number one 2020 draft pick",
        "answer": "EDWARDS",
        "row": 2,
        "col": 2
      },
      "6": {
        "clue": "Nickname for Gregg, Longtime coach of the Spurs",
        "answer": "POP",
        "row": 3,
        "col": 6
      },
      "7": {
        "clue": "90's Knicks' SG, first player with 200 3PM in a season ",
        "answer": "SMART",
        "row": 3,
        "col": 7
      },
      "8": {
        "clue": "'_____ shooting', going 1/9 from 3, maybe",
        "answer": "ROUGH",
        "row": 3,
        "col": 4
      },
      "9": {
        "clue": "College for Bulls Jordan and White, briefly",
        "answer": "UNC",
        "row": 7,
        "col": 5
      },
      "10": {
        "clue": "Coach Tyronn of the Cavs and Clippers",
        "answer": "LUE",
        "row": 7,
        "col": 8
      },
      "11": {
        "clue": "____ Reid, TWolves big man ",
        "answer": "NAZ",
        "row": 7,
        "col": 1
      },
    }
  },
  "EASTVSWEST":
  {
    "across": {
      "1": {
        "clue": "A team that steals a ticket into to March Madness by winning their conference tournament",
        "answer": "BIDTHIEF",
        "row": 0,
        "col": 1
      },
      "2": {
        "clue": "Dwyane Wade's nickname",
        "answer": "FLASH",
        "row": 2,
        "col": 4
      },
      "3": {
        "clue": "NBA's isolated gameplay location during the Pandemic",
        "answer": "BUBBLE",
        "row": 4,
        "col": 0
      },
      "4": {
        "clue": "Warriors' acronym",
        "answer": "GSW",
        "row": 5,
        "col": 6
      },
      "5": {
        "clue": "DeMarcus Cousins's nickname",
        "answer": "BOOGIE",
        "row": 7,
        "col": 2
      },
      "6": {
        "clue": "Hornets' city",
        "answer": "CHARLOTTE",
        "row": 9,
        "col": 0
      }
    },
    "down": {
      "1": {
        "clue": "NBA All-Star game matchup",
        "answer": "EASTVSWEST",
        "row": 0,
        "col": 7
      },
      "2": {
        "clue": "Anthony Davis's nickname",
        "answer": "THEBROW",
        "row": 1,
        "col": 0
      },
      "3": {
        "clue": "Bryant's first name",
        "answer": "KOBE",
        "row": 2,
        "col": 2
      },
      "4": {
        "clue": "Last name for Courtney or David",
        "answer": "LEE",
        "row": 2,
        "col": 5
      },
      "5": {
        "clue": "NBA symbol",
        "answer": "LOGO",
        "row": 4,
        "col": 4
      }
    }
  },

  "OBRIENTROPHY": {
    "across": {
      "1": {
        "clue": "Magic's city, for short",
        "answer": "ORL",
        "row": 0,
        "col": 0
      },
      "2": {
        "clue": "Elgin, or 2021 March Madness Champions",
        "answer": "BAYLOR",
        "row": 0,
        "col": 4
      },
      "3": {
        "clue": "First name of Hall of Famer Dumars",
        "answer": "JOE",
        "row": 1,
        "col": 10
      },
      "4": {
        "clue": "Sixers big man Paul best-known for his tenure in Atlanta",
        "answer": "MILLSAP",
        "row": 2,
        "col": 0
      },
      "5": {
        "clue": "Wizards' abbreviation",
        "answer": "WAS",
        "row": 3,
        "col": 7
      },
      "6": {
        "clue": "Request for divine assistance",
        "answer": "PRAYER",
        "row": 4,
        "col": 0
      },
      "7": {
        "clue": "Post-season college basketball tournament, briefly",
        "answer": "NIT",
        "row": 4,
        "col": 10
      },
      "8": {
        "clue": "Lonzo or LaMelo",
        "answer": "BALL",
        "row": 5,
        "col": 6
      },
      "9": {
        "clue": "The 'G' stands for gets?",
        "answer": "JIMMYGBUCKETS",
        "row": 7,
        "col": 0
      },
      "10": {
        "clue": "Rookie of the Year, for short",
        "answer": "ROY",
        "row": 9,
        "col": 10
      },
      "11": {
        "clue": "First name of Heat legend Wade",
        "answer": "DWYANE",
        "row": 10,
        "col": 0
      },
      "12": {
        "clue": "Nuggets' abbreviation",
        "answer": "DEN",
        "row": 10,
        "col": 7
      },
      "13": {
        "clue": "Suns' abbreviation",
        "answer": "PHX",
        "row": 11,
        "col": 10
      },
      "14": {
        "clue": "Shortened name for the Denver NBA team",
        "answer": "NUGGS",
        "row": 12,
        "col": 0
      }
    },
    "down": {
      "1": {
        "clue": "College b-ball ranking, or a college in Troy, NY",
        "answer": "RPI",
        "row": 0,
        "col": 1
      },
      "2": {
        "clue": "Celtics, on a scoreboard",
        "answer": "BOS",
        "row": 0,
        "col": 4
      },
      "3": {
        "clue": "It's hoisted by the NBA champions at the end of the season",
        "answer": "OBRIENTROPHY",
        "row": 1,
        "col": 11
      },
      "4": {
        "clue": "Nickname for Wiggins, paying tribute to his athleticism and Canadian roots",
        "answer": "MAPLEJORDAN",
        "row": 2,
        "col": 0
      },
      "5": {
        "clue": "Common offensive play in basketball",
        "answer": "ONBALLSCREEN",
        "row": 0,
        "col": 8
      },
      "6": {
        "clue": "Spurs' abbreviation",
        "answer": "LMA",
        "row": 6,
        "col": 3
      },
      "7": {
        "clue": "Rockets C with a role in John Wick 3",
        "answer": "BOBAN",
        "row": 5,
        "col": 6
      }
    }
  },

  "NBADRAFT":
  {
    "across": {
      "1": {
        "clue": "Celtics coach, familiarly",
        "answer": "BROWN",
        "row": 1,
        "col": 4
      },
      "2": {
        "clue": "Basketball position with a strong focus on defense",
        "answer": "WING",
        "row": 2,
        "col": 0
      },
      "3": {
        "clue": "Hoops target above the rim",
        "answer": "BACKBOARD",
        "row": 4,
        "col": 0
      },
      "4": {
        "clue": "Former NBA star Anthony",
        "answer": "MASE",
        "row": 6,
        "col": 0
      },
      "5": {
        "clue": "Heat, in sports headlines",
        "answer": "MIA",
        "row": 7,
        "col": 4
      },
      "6": {
        "clue": "European basketball league abbreviation",
        "answer": "ULEB",
        "row": 8,
        "col": 0
      }
    },
    "down": {
      "1": {
        "clue": "Warriors' home city, briefly",
        "answer": "GSW",
        "row": 0,
        "col": 0
      },
      "2": {
        "clue": "Pelicans' NBA abbreviation",
        "answer": "NOP",
        "row": 0,
        "col": 6
      },
      "3": {
        "clue": "New York team, informally",
        "answer": "KNICKS",
        "row": 1,
        "col": 2
      },
      "4": {
        "clue": "Annual event where teams pick new players",
        "answer": "NBADRAFT",
        "row": 1,
        "col": 8
      },
      "5": {
        "clue": "DeMarcus Cousins' nickname",
        "answer": "BOOGIE",
        "row": 3,
        "col": 5
      },
      "6": {
        "clue": "Miami's shot-blocking center",
        "answer": "BAM",
        "row": 4,
        "col": 0
      },
      "7": {
        "clue": "Hawks' city, for short",
        "answer": "ATL",
        "row": 6,
        "col": 1
      }
    }
  },
  "MINI_1": {
    "across": {
      "1": {
        "clue": "If, and, or ____",
        "answer": "BUT",
        "row": 0,
        "col": 2
      },
      "2": {
        "clue": "WNBA Team in Minnesota",
        "answer": "LYNX",
        "row": 1,
        "col": 1
      },
      "3": {
        "clue": "Rap group for 50 Cent and Lloyd Banks",
        "answer": "GUNIT",
        "row": 2,
        "col": 0
      },
      "6": {
        "clue": "Last name of Mehmet",
        "answer": "OKUR",
        "row": 3,
        "col": 0
      },
      "7": {
        "clue": "Adebayo of Miami",
        "answer": "BAM",
        "row": 4,
        "col": 0
      }
    },
    "down": {
      "1": {
        "clue": "Andrew ______, former lakers big man",
        "answer": "BYNUM",
        "row": 0,
        "col": 2
      },
      "2": {
        "clue": "Join, to Jean-Luc",
        "answer": "UNIR",
        "row": 0,
        "col": 3
      },
      "3": {
        "clue": "iMessage or SMS",
        "answer": "TXT",
        "row": 0,
        "col": 4
      },
      "4": {
        "clue": "Mavs star Doncic",
        "answer": "LUKA",
        "row": 1,
        "col": 1
      },
      "5": {
        "clue": "Wad, or the eldest of TV's Bluth children",
        "answer": "GOB",
        "row": 2,
        "col": 0
      }
    }
  },
  "MINI_2": {
    "across": {
      "1": {
        "clue": "Denzel ___entine",
        "answer": "VAL",
        "row": 0,
        "col": 2
      },
      "6": {
        "clue": "One of the Heat big 3, informally",
        "answer": "DWADE",
        "row": 1,
        "col": 0
      },
      "7": {
        "clue": "Bounce back from a defecit",
        "answer": "RALLY",
        "row": 2,
        "col": 0
      },
      "8": {
        "clue": "Holiday in boston?",
        "answer": "JRUE",
        "row": 3,
        "col": 0
      },
      "9": {
        "clue": "Metric for player efficiency, briefly",
        "answer": "PER",
        "row": 4,
        "col": 1
      }
    },
    "down": {
      "1": {
        "clue": "Worth",
        "answer": "VALUE",
        "row": 0,
        "col": 2
      },
      "2": {
        "clue": "Chicago's _______ Planetarium",
        "answer": "ADLER",
        "row": 0,
        "col": 3
      },
      "3": {
        "clue": "Law, in Madrid",
        "answer": "LEY",
        "row": 0,
        "col": 4
      },
      "4": {
        "clue": "NBA Legend with a degree in dunks?",
        "answer": "DRJ",
        "row": 1,
        "col": 0
      },
      "5": {
        "clue": "Distort",
        "answer": "WARP",
        "row": 1,
        "col": 1
      }
    }
  },
  "MINI_3": {
    'across': {
      1: {
        'clue': 'Presidential initials, or a Cleveland Cavaliers star in initials',
        'answer': 'LBJ',
        'row': 0,
        'col': 2
      },
      4: {
        'clue': 'Cavaliers wing with a knack for defense, Isaac ____',
        'answer': 'OKORO',
        'row': 1,
        'col': 0
      },
      6: { 'clue': 'Expert; connoisseur', 'answer': 'MAVIN', 'row': 2, 'col': 0 },
      7: {
        'clue': 'First name of a center from Croatia, played for the Lakers',
        'answer': 'IVICA',
        'row': 3,
        'col': 0
      },
      8: {
        'clue': 'Large storage vessels or to fail deliberately in a game for a better draft pick',
        'answer': 'TANKS',
        'row': 4,
        'col': 0
      }
    },
    'down': {
      1: {
        'clue': "Adoring, with 'up'",
        'answer': 'LOVIN',
        'row': 0,
        'col': 2
      },
      2: {
        'clue': 'A shot that clunks off the rim, in hoops slang',
        'answer': 'BRICK',
        'row': 0,
        'col': 3
      },
      3: {
        'clue': "Pelicans' big man Valančiūnas's first name",
        'answer': 'JONAS',
        'row': 0,
        'col': 4
      },
      4: { 'clue': 'Leave out or exclude', 'answer': 'OMIT', 'row': 1, 'col': 0 },
      5: {
        'clue': 'Pacific island drink that relaxes',
        'answer': 'KAVA',
        'row': 1,
        'col': 1
      }
    }
  },
  "MINI_4": {
    'across': {
      '1': {
        'clue': 'Royal initials for a famous Akron-born MVP',
        'answer': 'LBJ',
        'row': 0,
        'col': 1
      },
      '4': {
        'clue': "First name of Cavaliers' forward Okoro",
        'answer': 'ISAAC',
        'row': 1,
        'col': 0
      },
      '6': {
        'clue': 'Milicic, once a towering presence in the NBA draft',
        'answer': 'DARKO',
        'row': 2,
        'col': 0
      },
      '7': {
        'clue': "Sit on the doorstep or a Cavaliers' game repositioning",
        'answer': 'STOOP',
        'row': 3,
        'col': 0
      },
      '8': {
        'clue': 'Initials for the association with a Slam Dunk Contest',
        'answer': 'NBA',
        'row': 4,
        'col': 2
      }
    },
    'down': {
      '1': {
        'clue': 'Test for future attorneys, briefly',
        'answer': 'LSAT',
        'row': 0,
        'col': 1
      },
      '2': {
        'clue': 'First name of the guard Davis who once ruled the court in New Orleans and Golden State',
        'answer': 'BARON',
        'row': 0,
        'col': 2
      },
      '3': {
        'clue': "First name of Spurs' center Poeltl hailing from Austria",
        'answer': 'JAKOB',
        'row': 0,
        'col': 3
      },
      '4': {
        'clue': 'They confirm who you are, for short',
        'answer': 'IDS',
        'row': 1,
        'col': 0
      },
      '5': {
        'clue': 'Cup mentioned in soccer discussions, not to be confused with courtside cheers',
        'answer': 'COPA',
        'row': 1,
        'col': 4
      }
    }
  }
};



export default xword_dict;