import React from "react";
import './footer-styles.css';

const Footer = () => {
    return(
    <div className="copyright-statement">
        <b>Copyright © 2023 Ailette, All Rights Reserved.</b>
<p/>        
        The content on this website, including text, graphics, images, as well as the compilation thereof, and any software used on the site, is the property of Ailette.io or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. The material on this website may be used as a client resource for the purpose of this project proposal only. Any other use, including reproduction, modification, distribution, transmission, republication, display, or performance, of the content on this site is strictly prohibited without prior written consent from Ailette.io.

        <p />
    'Ailette' and 'Ailette.io' are a DBA of Timothy M. Howe.
    </div>
    )
}


export default Footer;