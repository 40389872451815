import React from "react";
import "./layout-styles.css";
import Footer from "../footer";
import Sidebar from "../sidebar";
import Header from "../header";

const Layout = ({ Content }) => {
  return (
    <div className="holy-grail-container">
      <header className="hd">
        <Header />
      </header>
      <Sidebar position="sd-left" />
      <main className="main">
        <Content />
      </main>
      <Sidebar position="sd-right" />
      <footer className="ft">
        <Footer />
      </footer>
    </div>
  );
};

export default Layout;
