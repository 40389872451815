import React from "react";
import "./current-clue-styles.css";
// import { realpathSync } from "fs";

// CurrentClue component that displays given clue in specified format
const CurrentClue = ({ dir, num, text, switchDirection}) => {
  return (
    <div className="current-clue" onClick={switchDirection}>
      {num} {dir}: {text}
    </div>
  );
};

export default CurrentClue;
