import React from "react";
import ReactDOM from "react-dom";

// import {
//   BrowserRouter as Router, 
//   Switch,
//   Route
// } from 'react-router-dom'

import App from "./App";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootElement
);
