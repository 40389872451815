import React from "react";
// import {createBrowserRouter, RouterProvider} from "react-router-dom"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Component } from "react";
import "./styles.css";
// import Xword from "./components/xword.js";

import Xword from "./pages/Xword";
import Home from "./pages/Home"

const center_style = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  padding: '3%',
  justifyContent: "center",
  alignItems: "center",
}

const footer_div_style = {
  position: "relative",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  justifyContent: "center",
  alignItems: "center",
}

// const router = createBrowserRouter([
//   {
//     path:"/",
//     element:<Home />,
//     children:{
//       path:"game",
//       element:<Crossword />,
//       // loader:"gameLoader"
//     }
//   }
// ])

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact Component={Home} />
        <Route path="/crossword" Component={Xword} />

      </Routes>
    </Router>
  );

}

export default App;

