import React, { useCallback } from "react";
import "./winner-winner-styles.css";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Renders the WinnerWinner component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.timerValue - The timer value.
 * @param {number} props.currentStreak - The current streak value.
 * @param {boolean} props.isOpen - Indicates whether the dialog is open or not.
 * @param {Function} props.onClose - The function to close the dialog.
 * @returns {JSX.Element|null} The rendered WinnerWinner component.
 */
const WinnerWinner = ({
  timerValue,
  currentStreak,
  isOpen,
  onClose,
  onNextPuzzle,
}) => {
  const handleClickOutside = useCallback(
    (e, reason) => {
      if (reason === "backdropClick") e.preventDefault();
      else onClose();
    },
    [onClose]
  );

  if (!isOpen) return null;

  const winStreak = 3;

  return (
    <div className="winner-winner-backdrop">
      <Dialog
        open={isOpen}
        onClose={(e, reason) => handleClickOutside(e, reason)}
        className="winner-winner-dialog"
        TransitionComponent={Transition}
        PaperProps={{
          className: "winner-winner-paper",
          square: true,
        }}
      >
        <Box className="winner-winner-box">
          <div className="close-button-container">
          <button className="close-button" onClick={onClose}>
            <CloseIcon />
          </button>
          </div>
          <div className="winner-winner-header">Congratulations!</div>

          <div className="winner-winner-body-container">
            <div className="winner-winner-body final-time">
              You finished in: {timerValue}
            </div>
            {/* <div className="winner-winner-body win-streak">
              {" "}
              Your streak is: {currentStreak}
            </div> */}
          </div>
          <div className="winner-winner-button-container">
            {/* <Button className="big-button winner"
              color={"primary"} variant="contained">Share your score!</Button> */}
            <Button
              className="big-button winner"
              onClick={onNextPuzzle}
              color={"primary"}
              variant="contained"
            >
              Choose Another Puzzle
            </Button>
          </div>
        </Box>
      </Dialog>
    </div>
  );
};

export default WinnerWinner;
